import http from "./http";

const BASE_URL = "/trainer";

const listTrainerByIdProduct = (idProduct: number, callback: any) => {
	http
		.get(`${BASE_URL}/training?id=${idProduct}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_TRAINER = {
	listTrainerByIdProduct,
};

import http from "./http";

const BASE_URL = "/inhouse";

const requestInhouse = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/create`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_INHOUSE = {
	requestInhouse,
};

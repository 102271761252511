import axios from "axios";
import store from "store";

import { CONSTANTS } from "@/utils";

const API_BASE_URL = process.env.NEXT_PUBLIC_URL_BASE_API;
const http = axios.create({
	baseURL: API_BASE_URL,
	timeout: 30_000,
});

const getAuthConfig = () => {
	const { token } = store.get(CONSTANTS.LS_KEY());
	return token ? { headers: { Authorization: `Bearer ${token}` } } : {};
};

export default http;
export { API_BASE_URL, getAuthConfig };

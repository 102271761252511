import http from "./http";

const BASE_URL = "/client";

const topClient = (params: string, callback: any) => {
	http
		.get(`${BASE_URL}/topclient${params}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_CLIENT = {
	topClient,
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";

import { Helpers } from "@/utils";

import http, { getAuthConfig } from "./http";

const BASE_URL = "/user";

const userUpdate = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/update`, payload, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_USER = {
	userUpdate,
};

// ========== NEW STUFF ==========

export function makeUserUpdateSchema(lang: string) {
	return Yup.object().shape({
		nama_lengkap: Yup.string().required(
			lang === "id" ? "Nama wajib diisi" : "Name is required",
		),
		email: Yup.string()
			.email()
			.required(lang === "id" ? "Email wajib diisi" : "Email is required"),
		no_handphone: Yup.string()
			.min(10)
			.required(
				lang === "id"
					? "Nomor telepon wajib diisi"
					: "Phone number is required",
			),
		jenis_kelamin: Yup.mixed().required(
			lang === "id" ? "Jenis kelamin wajib diisi" : "Gender is required",
		),
		date_birth: Yup.mixed().required(
			lang === "id" ? "Tanggal lahir wajib diisi" : "Date of birth is required",
		),
		address: Yup.string().required(
			lang === "id" ? "Alamat wajib diisi" : "Address is required",
		),
		instansi: Yup.string().required(
			lang === "id" ? "Instansi wajib diisi" : "Institution is required",
		),
	});
}

export type UserDetail = {
	id: number;
	name: string;
	nickname: string | null;
	email: string;
	password: string;
	remember_token: null;
	created_at: Date;
	updated_at: Date;
	status: number;
	token: string;
	phone: string;
	phone_office: string | null;
	userlevel: number | null;
	address: string | null;
	address_format: string | null;
	gender: string;
	title: string | null;
	city: string | null;
	province: string | null;
	country: string | null;
	about_me: string | null;
	lat: number | null;
	lng: number | null;
	keu_user_id: number | null;
	institution: string | null;
	date_birth: Date | null;
	photo: string | null;
	employee_id: number | null;
	id_card: null;
	roles: string[];
};

export const USER_QUERY_KEYS = {
	detail: "user-detail",
	ticketAdmins: "ticket-admins",
};

export async function getUserDetail(): Promise<UserDetail> {
	const userDetail = await http.get("/auth/get-user", getAuthConfig());
	return userDetail.data.user;
}

export function useUserDetail() {
	return useQuery<UserDetail>({
		queryKey: [USER_QUERY_KEYS.detail],
		queryFn: getUserDetail,
	});
}

type UserUpdatePayload = {
	id?: number;
	name: string;
	email: string;
	phone: string;
	institution: string;
	gender: string;
	address: string;
	dateBirth: Date | null;
	profileImage: string;
};

export function useUserUpdate() {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: async (payload: UserUpdatePayload) => {
			const formData = new FormData();
			formData.append("id", payload.id as unknown as string);
			formData.append("name", payload.name);
			formData.append("email", payload.email);
			formData.append("phone", payload.phone);
			formData.append("institution", payload.institution);
			formData.append("gender", payload.gender);
			formData.append("address", payload.address);
			formData.append(
				"date_birth",
				Helpers.formatDateDatabase(String(payload.dateBirth)),
			);

			if (payload.profileImage) {
				const croppedFile = new File(
					[await (await fetch(payload.profileImage)).blob()],
					"croppedImage.jpg",
					{ type: "image/jpeg" },
				);
				formData.append("photo", croppedFile);
			}

			await http.post("/user/update", formData, getAuthConfig());
		},
		async onSuccess() {
			await queryClient.invalidateQueries({
				queryKey: [USER_QUERY_KEYS.detail],
			});
		},
	});
}

export async function getTicketAdmins(): Promise<UserDetail[]> {
	const admins = await http.get("/user/all?role=ticket_admin&role=ticket_super_admin", getAuthConfig());
	return admins.data.users;
}

export function useTicketAdmins() {
	return useQuery<UserDetail[]>({
		queryKey: [USER_QUERY_KEYS.ticketAdmins],
		queryFn: getTicketAdmins,
	});
}

import http from "./http";

const BASE_URL = "/participant";

const listParticipant = (queries: string, callback: any) => {
	http
		.get(`${BASE_URL}/by-userid${queries}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateParticipant = (formData: any, callback: any) => {
	http
		.post(`${BASE_URL}/update`, formData)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const scoreParticipant = (formData: any, callback: any) => {
	http
	.post(`${BASE_URL}/score`, formData)
	.then((res) => callback({ payload: res.data, error: null, success: true }))
	.catch((error) =>
		callback({ payload: null, error: error.response, success: false }),
	);
}

const deleteParticipant = (idParticipant: number, callback: any) => {
	http
		.post(`${BASE_URL}/delete`, { id: idParticipant })
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const listParticipantByIdProduct = (idProduct: number, callback: any) => {
	http
		.get(`${BASE_URL}/training?id=${idProduct}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_PARTICIPANT = {
	listParticipant,
	updateParticipant,
	scoreParticipant,
	deleteParticipant,
	listParticipantByIdProduct,
};

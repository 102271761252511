import http from "./http";

const BASE_URL = "/blog";

const listArticle = (params: string, callback: any) => {
	http
		.get(`${BASE_URL}${params}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const searchArticle = (queries: string, callback: any) => {
	http
		.get(`${BASE_URL}/search${queries}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const detailArticle = (idTraining: number, callback: any) => {
	http
		.post(`${BASE_URL}/detailArticle?id=${idTraining}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_BLOG = {
	listArticle,
	searchArticle,
	detailArticle,
};

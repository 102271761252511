import http from "./http";

const BASE_URL = "/certificate";

const verifyCertificate = (certificateCode: string, callback) => {
	http
		.post(`${BASE_URL}/verify`, { certificate_code: certificateCode })
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const printCertificate = (certificateCode: string, callback: any) => {
	http
		.post(`${BASE_URL}/print`, { certificate_code: certificateCode })
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_CERTIFICATE = {
	verifyCertificate,
	printCertificate,
};

import http from "./http";

const BASE_URL = "/study-method";

const studyMethodList = (callback: any) => {
	http
		.get(`${BASE_URL}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_STUDY_METHOD = {
	studyMethodList,
};

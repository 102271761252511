import http from "./http";

const BASE_URL = "/feedback";

const feedbackTransaction = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/transaction/create`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_FEEDBACK = {
	feedbackTransaction,
};

import http from "./http";

const BASE_URL = "/agency";

const typeAgency = (callback: any) => {
	http
		.get(`${BASE_URL}/type`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateAgency = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/update`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const deleteAgency = (idAgency: number, callback: any) => {
	http
		.post(`${BASE_URL}/delete`, { id: idAgency })
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_AGENCY = {
	typeAgency,
	updateAgency,
	deleteAgency,
};

import http from "./http";

const BASE_URL = "/kuesioner";

const submitQuestionnairePeserta = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/peserta`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const submitQuestionnaireTrainer = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/trainer`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_KUESIONER = {
	submitQuestionnairePeserta,
	submitQuestionnaireTrainer,
};

import http, { getAuthConfig } from "./http";

const BASE_URL = "/auth";
const login = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/login`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const register = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/register`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const verify = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/verify`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const forgetPassword = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/password/create`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const resetPassword = (payload: any, callback: any) => {
	http
		.post(`${BASE_URL}/password/reset`, payload)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const detailUser = (callback: any) => {
	http
		.get(`${BASE_URL}/get-user`, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const detailUserByTokenSkilly = (token: string | string[], callback: any) => {
	http
		.get(`${BASE_URL}/get-user`, {
			headers: { Authorization: `Bearer ${token}` },
		})
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_AUTHENTICATION = {
	login,
	register,
	verify,
	forgetPassword,
	resetPassword,
	detailUser,
	detailUserByTokenSkilly,
};

import http from "./http";

const BASE_URL = "/training";

const searchProduct = (queries: string, callback: any) => {
	http
		.get(`${BASE_URL}/search${queries}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const topProduct = (params: string, callback: any) => {
	http
		.get(`${BASE_URL}/toptraining${params}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const detailProduct = (idTraining: string, callback: any) => {
	http
		.post(`${BASE_URL}/detailTraining?id=${idTraining}`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const currentProduct = (callback: any) => {
	http
		.get(`${BASE_URL}/current`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const schedule = (
	clusterId: number | string,
	trainingTypeId: number | string,
	callback: any,
) => {
	http
		.get(
			`${BASE_URL}/schedule?cluster_type=${clusterId}&training_type=${trainingTypeId}`,
		)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const trainingType = (callback: any) => {
	http
		.get(`${BASE_URL}/trainingtype`)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_PRODUCT = {
	searchProduct,
	topProduct,
	detailProduct,
	currentProduct,
	schedule,
	trainingType,
};
